<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import upperFirst from 'lodash/upperFirst';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import fleetRoutes from '@domains/Carsharing/router/FleetRouterMap';
import crmRoutes from '@domains/CRM/router/CRMRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { AUDIT_ENTITIES } from '@domains/Shared/constants/audit.const';

import { MuiFileListItem } from '@emobg/motion-ui';
import { MuiAlgoliaSelect, MuiSelect } from '@emobg/motion-ui/v1';
import {
  base64WithoutName,
  camelCaseKeys,
  DATE_FORMAT,
  getImageName,
  reformatDateTime,
  removeBase64HeaderArray,
  sentenceCase,
} from '@emobg/web-utils';
import {
  Actions,
  CommentsList,
  ContentCellComponent,
  PageView,
  PermissionLink,
} from '@/components';
import { COMMENTABLE_TYPES } from '@/components/Comments/v1/constants/comments';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import {
  CaseFileUploaderComponent,
  CaseHistoryComponent,
  CopyToClipboardComponent,
  DeleteCaseMediaModal,
  RelatesToComponent,
} from './components';
import {
  ALGOLIA_INDEXES,
  CASE_PRIORITIES,
  CASE_STATUS,
  ENTITIES,
} from './const';
import { casesStatusColors } from '../../const/status';
import { PRIORITIES_COLOR } from '../../const/priorities';

export default {
  name: 'CaseEventsDetailView',
  components: {
    Actions,
    CaseFileUploaderComponent,
    CaseHistoryComponent,
    CommentsList,
    ContentCellComponent,
    CopyToClipboardComponent,
    DeleteCaseMediaModal,
    MuiAlgoliaSelect,
    MuiFileListItem,
    MuiSelect,
    PageView,
    PermissionLink,
    RelatesToComponent,
  },
  data() {
    return {
      CASE_STATUS,
      CASE_PRIORITIES,
      isDeleteMediaModalOpen: false,
      areInputsReady: false,
      currentFileToDelete: {
        index: -1,
        media: '',
      },
      agents: [],
      userUuids: [],
      originalInputs: undefined,
      inputs: {
        assigneeUserUuid: null,
        bookingUuid: null,
        caseFiles: [],
        caseTypeUuid: null,
        comment: '',
        linkedEntities: [],
        mainEntity: get(this, '$route.query.entity', null),
        priority: 'normal',
        reportingUserUuid: null,
        status: 'open',
        userUuid: null,
        uuid: get(this, '$route.params.caseEventUuid'),
        vehicleUuid: null,
      },
    };
  },
  computed: {
    isActionDisabled() {
      return !this.areAllRequiredFieldsFilled || (!this.areInputsModified && !this.userUuids.length);
    },
    actionText() {
      const mainAction = this.isEditing ? this.$t('Common.Actions.save') : this.$t('Common.Actions.create');

      if (!this.areInputsModified && get(this, 'userUuids.length')) {
        return this.$t('Common.Actions.share');
      }

      return `${mainAction} ${get(this, 'userUuids.length') ? 'and Share' : ''}`;
    },
    areAllRequiredFieldsFilled() {
      return this.inputs.caseTypeUuid
        && this.inputs.status
        && this.inputs.priority;
    },
    areInputsModified() {
      return !isEqual(this.inputs, this.originalInputs) || !this.isEditing;
    },
    dataToSend() {
      const dataToSend = cloneDeep(this.inputs);
      dataToSend.caseFiles = this.filesWithoutHeader;
      dataToSend.linkedEntities = map(dataToSend.linkedEntities, ({ entity, entityUuid, uuid }) => ({ entity, entityUuid, uuid }));

      return {
        caseEventUuid: this.caseEvent.uuid,
        request: dataToSend,
      };
    },
    filterCaseTypes() {
      return `${this.inputs.mainEntity}_available: 1`;
    },
    filterShareAgents() {
      let filter = this.getOperatorFilter({ attribute: 'cs_operators.id' });
      const sharedAgents = get(this.caseEvent, 'sharedWith', []);

      filter = reduce(
        sharedAgents,
        (filterString, sharedAgent) => `${filterString} AND NOT user_id:${sharedAgent.id}`,
        filter,
      );

      return filter;
    },
    filesWithoutHeader() {
      return removeBase64HeaderArray(get(this.inputs, 'caseFiles'));
    },
    isEditing() {
      return !isEmpty(get(this, '$route.params.caseEventUuid'));
    },
    sentenceCase: () => sentenceCase,
    title() {
      const caseType = this.caseEvent.caseType ? `- ${this.caseEvent.caseType}` : '';
      const element = this.$t('Common.Business.case');
      return this.isEditing
        ? `${upperFirst(element)} #${this.caseEvent.id} ${caseType}`
        : this.$t('Common.Actions.create_element', { element });
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
      currentUser: state => state.user.data,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    ...mapState(DOMAINS_MODEL.casesServices.caseEvents.detail, {
      status: state => state.STATUS,
      caseEvent: state => state.data,
      error: state => state.error,
      mediaStatusError: state => state.media.STATUS.ERROR,
    }),
    ...mapState(DOMAINS_MODEL.shared.audit, {
      caseEventAuditData: state => state.data,
    }),
    ...mapState(DOMAINS_MODEL.app.comments, {
      comments: state => state.comments.data || [],
      areCommentsLoading: state => state.comments.STATUS.LOADING,
    }),
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    const entity = get(this, '$route.query.entity');
    const entityUuid = get(this, '$route.query.entityUUID');
    const uuid = get(this, '$route.params.caseEventUuid');
    this.carsharingRoutes = carsharingRoutes;
    this.crmRoutes = crmRoutes;
    this.fleetRoutes = fleetRoutes;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.casesStatusColors = casesStatusColors;
    this.PRIORITIES_COLOR = PRIORITIES_COLOR;

    this.actionsComponentProps = {
      title: this.title,
      user: this.caseEvent.user,
      booking: this.caseEvent.booking,
      vehicle: this.caseEvent.vehicle,
      reportingUser: this.caseEvent.reportingUser,
      caseEvent: this.inputs,
    };

    if (this.isEditing) {
      await this.getCaseEvent(uuid);
      if (!this.status.ERROR) {
        await Promise.all([
          this.fillInputs(),
          this.fetchCaseComments(),
        ]);
      }
    } else if (entity && entityUuid) {
      await this.setupEntities(entity, entityUuid);
    } else {
      this.$notify({
        text: this.$t('Common.Errors.no_provided_element', { element: upperFirst(this.$t('Common.Business.entity')) }),
        type: NOTIFICATION_TYPES.error,
      });
    }
  },
  methods: {
    base64WithoutName,
    get,
    getImageName,
    map,
    upperFirst,
    ...mapActions(DOMAINS_MODEL.casesServices.caseEvents.detail, [
      'getCaseEvent',
      'postCaseEvent',
      'putCaseEvent',
      'postShareCaseEvent',
      'deleteCaseMedia',
    ]),
    ...mapActions(DOMAINS_MODEL.shared.audit, [
      'getAuditData',
    ]),
    ...mapMutations(DOMAINS_MODEL.casesServices.caseEvents.detail, [
      'setData',
    ]),
    ...mapActions(DOMAINS_MODEL.app.comments, [
      'getCommentsByType',
    ]),
    redirect() {
      const { type, url } = this.urlToRedirect();
      if (type === 'routerLink') {
        this.$router.push(url);
      } else {
        window.location = url;
      }
    },
    async fetchCaseComments() {
      await this.getCommentsByType({
        commentableUuid: this.caseEvent.uuid,
        commentableType: COMMENTABLE_TYPES.caseEvent,
      });
    },
    async setupEntities(entity, entityUuid) {
      this.caseEvent.mainEntity = entity;
      this.caseEvent.reportingUser = camelCaseKeys(this.currentUser);
      this.inputs.reportingUserUuid = get(this.caseEvent, 'reportingUser.uuid');
      this.inputs[`${entity}Uuid`] = entityUuid;
      this.areInputsReady = true;

      try {
        const response = await this.$algolia.fetchIndex(ALGOLIA_INDEXES[entity], {
          filters: `uuid:${entityUuid}`,
        });

        const caseEventData = {
          [entity]: camelCaseKeys(get(response.hits, '0', null)),
        };
        this.setData({ value: caseEventData });

        if (!this.caseEvent[entity]) {
          throw new Error(this.$t('Common.Errors.no_found_element', { element: upperFirst(this.$t('Common.Business.entity')) }));
        }

        if (entity === ENTITIES.booking && this.caseEvent[entity]) {
          this.fillUserAndVehicleFromBooking(this.caseEvent.booking);
        }
      } catch (e) {
        this.$throwError(e);
      }
    },
    async fillInputs() {
      this.areInputsReady = false;
      await this.getAuditData({ primaryEntity: AUDIT_ENTITIES.caseEvent, primaryId: get(this.caseEvent, 'id', -1) });

      this.inputs.bookingUuid = get(this, 'caseEvent.booking.uuid');
      this.inputs.comment = get(this, 'caseEvent.comment', '');
      this.inputs.linkedEntities = get(this, 'caseEvent.caseEventLinks', []);
      this.inputs.mainEntity = get(this, 'caseEvent.mainEntity');
      this.inputs.priority = get(this, 'caseEvent.priority', 'normal');
      this.inputs.reportingUserUuid = get(this, 'caseEvent.reportingUser.uuid');
      this.inputs.caseTypeUuid = get(this, 'caseEvent.caseTypeUuid');
      this.inputs.assigneeUserUuid = get(this, 'caseEvent.assigneeUser.uuid');
      this.inputs.status = get(this, 'caseEvent.status');
      this.inputs.userUuid = get(this, 'caseEvent.user.uuid');
      this.inputs.uuid = get(this, 'caseEvent.uuid');
      this.inputs.vehicleUuid = get(this, 'caseEvent.vehicle.uuid');
      this.inputs.caseFiles = [];
      this.areInputsReady = true;
      this.originalInputs = cloneDeep(this.inputs);
    },
    async doRequest() {
      const request = this.isEditing ? this.putCaseEvent : this.postCaseEvent;

      if (this.areInputsModified) {
        await request(this.dataToSend);
      }

      if (!this.status.ERROR) {
        await this.fillInputs();
        if (this.userUuids.length) {
          await this.postShareCaseEvent({
            caseEventUuid: this.caseEvent.uuid,
            userUuids: this.userUuids,
          });
        }

        this.redirect();
      }
    },
    async onDeleteMediaCallback(source, index) {
      if (source === 'caseEvent.mediaFiles') {
        const caseMediaUuid = get(this.caseEvent, `mediaFiles[${index}].uuid`);
        const caseEventUuid = get(this.caseEvent, 'uuid');

        await this.deleteCaseMedia({
          caseEventUuid,
          caseMediaUuid,
        });
        if (this.mediaStatusError) {
          return;
        }
      }
      get(this, source, this.inputs.caseFiles).splice(index, 1);
    },
    onChangeImage(_$event, files) {
      this.inputs.caseFiles = get(this.inputs, 'caseFiles', []).concat(files);
    },
    removeFile(index, alreadyCreated = false) {
      this.isDeleteMediaModalOpen = true;
      this.currentFileToDelete.media = alreadyCreated ? get(this.caseEvent.mediaFiles, index) : this.inputs.caseFiles[index];
      this.currentFileToDelete.index = index;
    },
    fillUserAndVehicleFromBooking(booking) {
      this.caseEvent.user = {
        id: booking.userFk,
        firstName: booking.userName,
        lastName: '',
      };

      this.caseEvent.vehicle = {
        id: booking.vehicleFk,
        licensePlate: booking.vehiclePlate,
      };
    },
    urlToRedirect() {
      const entity = this.caseEvent.mainEntity;
      const { uuid } = this.caseEvent[entity];

      switch (entity) {
        case 'booking':
          return { type: 'routerLink', url: `/carsharing/bookings/${uuid}/cases` };
        case 'vehicle':
          return { type: 'routerLink', url: `/fleet/vehicles/${uuid}/details/cases` };
        case 'user':
          return { type: 'routerLink', url: `/user-company/users/${uuid}/cases` };
        default:
          return { type: 'routerLink', url: '/cases-service/case-events' };
      }
    },
    sharedWithTemplate(result) {
      return `<a
                target='_blank'
                href='/user-company/users/${result.uuid}'
                class='emobg-link color-main emobg-font-small'
              >
                ${result.firstName} ${result.lastName}
              </a> - ${result.email}`;
    },
    auditTemplate(result) {
      const dateFormated = reformatDateTime(result.createdAt, DATE_FORMAT.defaultExtended, this.operatorTimezone);
      const action = sentenceCase(get(result, 'auditAction', '').replace('case_event_', ''));
      return `
              ${action} by
              <span class='emobg-font-small'>
                ${result.userName}
              </span> (${dateFormated})`;
    },
  },
};
</script>

<template>
  <PageView
    class="CaseEventsDetailView d-flex flex-column flex-fill"
    data-test-id="case_event_detail-view"
  >
    <ui-loader v-if="status.LOADING" />
    <div v-show="!status.LOADING && areInputsReady">
      <div class="row pb-2">
        <div class="col-12 d-flex align-items-center justify-content-between">
          <h2 class="emobg-font-weight-bold emobg-color-ink">
            {{ title }}
          </h2>
          <Actions>
            <CopyToClipboardComponent
              v-bind="actionsComponentProps"
              class="p-2 my-2"
            />
          </Actions>
        </div>
      </div>
      <ui-card class="d-flex flex-column flex-fill">
        <div class="flex-fill m-n3">
          <div class="d-flex flex-wrap">
            <div class="col-12 col-md-7 p-3">
              <h2 class="emobg-font-weight-bold">
                {{ $t('Common.Descriptions.details_element', { element: upperFirst($t('Common.Business.case'))}) }}
              </h2>
              <hr class="emobg-border-top-2 emobg-border-color-ground-light my-3">

              <ui-alert
                v-if="caseEvent.entityModified"
                :color="COLORS.warning"
                :icon="ICONS.alertFull"
                class="d-block mb-4 w-100"
              >
                {{ $t('CasesServices.Common.user_status_updated') }}
              </ui-alert>
              <div class="row">
                <div
                  v-if="caseEvent.booking"
                  class="col-12 col-lg-4 pb-4"
                >
                  <label class="d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink">
                    Booking number
                  </label>
                  <PermissionLink
                    :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingBookings]"
                    :to="{
                      name: carsharingRoutes.bookings.detail.index,
                      params: {
                        bookingUuid: get(caseEvent, 'booking.uuid'),
                      },
                    }"
                    class="emobg-link-primary emobg-body-2"
                  >
                    #{{ get(caseEvent, 'booking.id') }}
                  </PermissionLink>
                </div>

                <div
                  v-if="caseEvent.user"
                  class="col-12 col-lg-4 pb-4"
                >
                  <label class="d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink">
                    User
                  </label>
                  <PermissionLink
                    :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
                    :to="{
                      name: crmRoutes.users.detail.index,
                      params: {
                        userUuid: get(caseEvent, 'user.uuid'),
                      },
                    }"
                    target="_blank"
                    class="emobg-link-primary emobg-body-2"
                  >
                    {{ get(caseEvent, 'user.firstName') }} {{ get(caseEvent, 'user.lastName') }}
                  </PermissionLink>
                </div>

                <div
                  v-if="caseEvent.vehicle"
                  class="col-12 col-lg-4 pb-4"
                >
                  <label class="d-block pb-1 emobg-body-2 emobg-color-ink">
                    Vehicle
                  </label>
                  <PermissionLink
                    :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingVehicles]"
                    :to="{
                      name: fleetRoutes.vehicles.detail.details,
                      params: {
                        vehicle_uuid: get(caseEvent, 'vehicle.uuid'),
                      },
                    }"
                    target="_blank"
                    class="emobg-link-primary emobg-body-2"
                  >
                    {{ get(caseEvent, 'vehicle.licensePlate') }}
                  </PermissionLink>
                </div>
              </div>

              <MuiAlgoliaSelect
                v-if="areInputsReady"
                v-model="inputs.caseTypeUuid"
                :title="result => `${sentenceCase(result.internal_name)}`"
                :filters="filterCaseTypes"
                :results-per-page="50"
                :disabled="get(caseEvent, 'reportingUser.id', null) === 1 && isEditing"
                :index="ALGOLIA_INDEXES.caseTypes"
                name="case_type_uuid"
                :label="$t('Common.Descriptions.type_element', { element: upperFirst($t('Common.Business.case'))}) + '*'"
                :placeholder="$t('Common.Actions.select_element', {
                  element: $t('Common.Descriptions.type_element', {
                    element: upperFirst($t('Common.Business.case'))})})"
                path-value="uuid"
                class="w-100 pb-4"
              />

              <ui-text-area
                v-if="!isEditing"
                :value="inputs.comment"
                label="Description"
                class="Ui-TextArea--h-auto w-100"
                name="description"
                rows="7"
                @changevalue="event => inputs.comment = event.detail"
              />
              <ContentCellComponent
                v-else
                label="Description"
              >
                <div style="white-space: pre-wrap;">
                  {{ inputs.comment }}
                </div>
              </ContentCellComponent>

              <hr class="emobg-border-top-2 emobg-border-color-ground-light my-4">

              <label class="d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink">
                {{ upperFirst($t('Common.Comparisons.relates_to')) }}
              </label>
              <RelatesToComponent
                v-if="areInputsReady"
                v-model="inputs.linkedEntities"
              />

              <hr class="emobg-border-top-2 emobg-border-color-ground-light my-4">
              <label
                class="d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink"
                for="fileUploader"
              >
                {{ upperFirst($t('Common.Descriptions.attachments')) }}
              </label>
              <CaseFileUploaderComponent
                id="fileUploader"
                :on-change="onChangeImage"
                accept="image/png,image/jpg,image/jpeg,application/pdf"
              />
              <ul
                v-for="(file, fileIndex) in inputs.caseFiles"
                :key="`file_upload_${fileIndex}`"
                class="mt-4 col-lg-9 "
              >
                <MuiFileListItem
                  class="emobg-link-primary emobg-body-2"
                  :file="{
                    src: base64WithoutName(file),
                    name: getImageName(file)
                  }"
                  :delete-action="() => removeFile(fileIndex)"
                />
              </ul>
              <ul
                v-for="(file, fileIndex) in get(caseEvent, 'mediaFiles', [])"
                :key="`file_created_${fileIndex}`"
                class="mt-4 col-lg-9 "
              >
                <MuiFileListItem
                  class="emobg-link-primary emobg-body-2"
                  :file="{
                    src: file.url,
                    name: file.name
                  }"
                  :delete-action="() => removeFile(fileIndex, true)"
                />
              </ul>

              <hr class="emobg-border-top-2 emobg-border-color-ground-light my-4">

              <CommentsList
                v-if="isEditing"
                :comments="comments"
                :is-loading="areCommentsLoading"
                :callback="fetchCaseComments"
                :entity-uuid="caseEvent.uuid"
                entity="case_event"
                data-test-id="comments"
              >
                <template #header>
                  <h2 class="emobg-font-weight-bold">
                    Case comments
                  </h2>
                </template>
              </CommentsList>
            </div>
            <div class="col-12 col-md-5 p-3 emobg-background-color-ground-lightest">
              <h2 class="emobg-font-weight-bold">
                {{ $t('Common.Descriptions.management_element', { element: upperFirst($t('Common.Business.case'))}) }}
              </h2>
              <hr class="emobg-border-top-2 emobg-border-color-ground-light my-3">

              <div
                v-if="caseEvent.cityName"
                class="pb-4"
              >
                <label class="d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink">
                  {{ upperFirst($t('Common.Business.city')) }}
                </label>
                <span class="pl-1 emobg-font-small"> {{ caseEvent.cityName }} </span>
              </div>

              <div
                v-if="caseEvent.reportingUser"
                class="pb-4"
              >
                <label class="d-block emobg-font-small pb-1 pl-1 emobg-font-weight-bold emobg-color-ink">
                  {{ upperFirst($t('Common.ActionsFinished.reported_by')) }}
                </label>
                <a
                  :href="`/user-company/users/${get(caseEvent, 'reportingUser.uuid')}`"
                  target="_blank"
                  class="emobg-link color-main pl-1 emobg-font-small"
                >
                  {{ get(caseEvent, 'reportingUser.firstName') }} {{ get(caseEvent, 'reportingUser.lastName') }}
                </a>
              </div>

              <MuiAlgoliaSelect
                v-if="areInputsReady"
                v-model="inputs.assigneeUserUuid"
                :results-per-page="50"
                :filters="getOperatorFilter({ attribute: 'cs_operators.id' })"
                :title="result => `${result.user.first_name} ${result.user.last_name} - ${result.user ? result.user.email : '[No email]'}`"
                :label="upperFirst($t('Common.Descriptions.assignee'))"
                :index="ALGOLIA_INDEXES.agents"
                :placeholder="$t('Common.Actions.select_element', { element: $t('Common.Business.agent')})"
                name="agent_id"
                path-value="user.uuid"
                class="w-100 pb-4"
              />

              <MuiSelect
                v-model="inputs.status"
                :label="upperFirst($t('Common.Descriptions.status'))"
                :options="map(CASE_STATUS, status => ({ label: upperFirst(status), value: status }))"
                name="status"
                class="w-100 mb-3"
              >
                <template #selected="{ item }">
                  <ui-badge
                    :text="item.label"
                    :color="casesStatusColors[item.value]"
                  />
                </template>
                <template #item="{ item }">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <span class="emobg-font-weight-semibold">{{ item.label }}</span>
                    <ui-badge
                      :color="casesStatusColors[item.value]"
                      circle
                    />
                  </div>
                </template>
              </MuiSelect>

              <MuiSelect
                v-model="inputs.priority"
                :label="upperFirst($t('Common.Descriptions.priority'))"
                :options="map(CASE_PRIORITIES, priority => ({ label: upperFirst(priority), value: priority }))"
                name="priority"
                class="w-100 mb-1"
              >
                <template #selected="{ item }">
                  <ui-badge
                    :text="item.label"
                    :color="PRIORITIES_COLOR[item.value]"
                  />
                </template>
                <template #item="{ item }">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <span class="emobg-font-weight-semibold">{{ item.label }}</span>
                    <ui-badge
                      :color="PRIORITIES_COLOR[item.value]"
                      circle
                    />
                  </div>
                </template>
              </MuiSelect>

              <hr class="emobg-border-top-2 emobg-border-color-ground-light my-4">

              <label class="d-block emobg-font-small pb-1 emobg-font-weight-bold pl-0 emobg-color-ink">
                {{ $t('Common.Actions.share_with_element', { element: $t('Common.Business.agents')}) }}
              </label>
              <MuiAlgoliaSelect
                v-if="areInputsReady"
                v-model="userUuids"
                :filters="filterShareAgents"
                :title="result => `${result.user.first_name} ${result.user.last_name} - ${result.user.email}`"
                :placeholder="$t('Common.Actions.select_element', { element: $t('Common.Business.agents')})"
                multiple
                :index="ALGOLIA_INDEXES.agents"
                path-value="user.uuid"
                name="agents"
                class="w-100 pb-4 m-0"
                @selected="items => agents = items"
              />

              <label class="d-block emobg-font-small pb-1 emobg-font-weight-bold pl-0 emobg-color-ink">
                {{ $t('Common.Actions.share_element', { element: $t('Common.Descriptions.history')}) }}
              </label>
              <CaseHistoryComponent
                :items="caseEvent.sharedWith"
                :item-template="sharedWithTemplate"
              />

              <hr class="emobg-border-top-2 emobg-border-color-ground-light my-4">

              <label class="d-block emobg-font-small pb-1 emobg-font-weight-bold emobg-color-ink">
                Case history
              </label>
              <CaseHistoryComponent
                :items="caseEventAuditData"
                :item-template="auditTemplate"
              />
            </div>
          </div>
          <div class="emobg-border-top-1 emobg-border-color-ground-light p-4">
            <div class="d-flex align-items-center justify-content-end">
              <ui-button
                :color="GRAYSCALE.inkLight"
                :face="FACES.text"
                class="mr-2"
                @clickbutton="redirect"
              >
                {{ $t('Common.Actions.cancel') }}
              </ui-button>
              <ui-button
                :disabled="isActionDisabled"
                @clickbutton="doRequest"
              >
                {{ actionText }}
              </ui-button>
            </div>
          </div>
        </div>
      </ui-card>
    </div>

    <DeleteCaseMediaModal
      v-if="isDeleteMediaModalOpen"
      :file="currentFileToDelete"
      :on-delete="onDeleteMediaCallback"
      @closeModal="() => isDeleteMediaModalOpen = false"
    />
  </PageView>
</template>
