<script>
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import join from 'lodash/join';
import map from 'lodash/map';
import omit from 'lodash/omit';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import each from 'lodash/each';
import { MuiAlgoliaSelect, MuiSelect } from '@emobg/motion-ui/v1';
import { mapGetters } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { entitiesConfiguration } from './config';

export default {
  components: {
    MuiAlgoliaSelect,
    MuiSelect,
  },
  model: {
    prop: 'existingItems',
    event: 'change',
  },
  props: {
    existingItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      itemModel: null,
      currentItem: {
        entity: '',
        entityUuid: '',
        algoliaResult: null,
      },
      items: [],
    };
  },
  computed: {
    entities() {
      return entitiesConfiguration(this.getOperatorFilter);
    },
    entitiesList() {
      return map(this.entities, (entity, key) => ({ value: key, label: entity.label }));
    },
    filters() {
      return this.currentItem.entity
        ? `${this.entities[this.currentItem.entity].filters} ${this.omitSelectedByEntity}`
        : null;
    },
    index() {
      return this.currentItem.entity ? this.entities[this.currentItem.entity].index : ALGOLIA_INDEXES.csBookings;
    },
    title() {
      return this.currentItem.entity ? this.entities[this.currentItem.entity].templateSelect : () => '';
    },
    omitSelectedByEntity() {
      const filteredItems = filter(this.items, item => item.entity === this.currentItem.entity);
      let filterQuery = '';

      forEach(filteredItems, item => {
        filterQuery = `${filterQuery} AND NOT uuid:${item.entityUuid}`;
      });

      return filterQuery;
    },
    itemsClean() {
      return map(this.items, item => omit(item, ['algoliaResult']));
    },
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['getOperatorFilter']),
  },
  created() {
    if (this.existingItems.length) {
      this.fillItemsFromAlgolia();
    }
  },
  methods: {
    upperFirst,
    each,
    get,
    async getAlgoliaResults() {
      const entities = uniq(map(this.existingItems, item => item.entity));
      const algoliaPromises = [];

      each(entities, entity => {
        const filteredItems = filter(this.existingItems, item => item.entity === entity);
        const filtersUuids = map(filteredItems, item => `uuid: ${item.entityUuid}`);

        algoliaPromises.push({
          entity,
          promise: this.$algolia.fetchIndex(get(this.entities[entity], 'index'), {
            filters: join(filtersUuids, ' OR '),
            hitsPerPage: 50,
          }),
        });
      });

      return {
        entities,
        results: await Promise.all(map(algoliaPromises, ({ promise }) => promise)),
      };
    },
    async fillItemsFromAlgolia() {
      const { entities, results } = await this.getAlgoliaResults();
      const existingItemsCloned = cloneDeep(this.existingItems);
      this.items = map(existingItemsCloned, item => {
        const index = indexOf(entities, item.entity);
        const hits = get(results[index], 'hits');

        set(item, 'algoliaResult', find(hits, algoliaObject => algoliaObject.uuid === item.entityUuid));

        return item;
      });
    },
    addCurrentItemToItems() {
      this.items.push(cloneDeep(this.currentItem));
      this.resetCurrentItem();
      this.$emit('change', this.itemsClean);
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.$emit('change', this.itemsClean);
    },
    resetCurrentItem() {
      this.currentItem.entityUuid = '';
      this.currentItem.entity = '';
      this.currentItem.algoliaResult = null;
      this.itemModel = null;
    },
    fillCurrentItem(item) {
      this.currentItem.algoliaResult = item;
      this.currentItem.entityUuid = get(item, 'uuid');
    },
    templateToShow(item, target) {
      const entity = get(this.entities, item.entity);
      const targetFunction = entity ? get(entity, target) : () => '';

      return targetFunction(item.algoliaResult);
    },
  },
};
</script>
<template>
  <div class="RelatesToComponent">
    <div class="row">
      <div class="col-12 col-sm-3 col-md-4 col-lg-3">
        <MuiSelect
          v-model="currentItem.entity"
          :placeholder="upperFirst($t('Common.Business.entity'))"
          :options="entitiesList"
          class="w-100 mb-2 mb-sm-0"
          name="entity"
          @change="() => currentItem.entityUuid = ''"
        />
      </div>
      <div class="col-12 col-sm-7 col-md-8 col-lg min-width--none">
        <MuiAlgoliaSelect
          :key="currentItem.entity"
          v-model="itemModel"
          :filters="filters || ''"
          :title="title"
          :index="index"
          :disabled="!currentItem.entity"
          :placeholder="upperFirst($t('Common.Comparisons.relation'))"
          path-value="uuid"
          name="entityUuid"
          class="w-100 mb-2 mb-sm-0"
          @selected="fillCurrentItem"
        />
      </div>
      <div class="d-flex justify-content-end col-12 col-sm-2 col-md-12 col-lg-auto">
        <ui-button
          :disabled="!currentItem.entityUuid"
          :face="FACES.outline"
          class="wmin-initial font-s"
          @clickbutton="addCurrentItemToItems"
        >
          {{ $t('Common.Actions.add') }}
        </ui-button>
      </div>
    </div>
    <div
      v-for="(item, itemIndex) in items"
      :key="item.entityUuid"
      class="row align-items-center emobg-font-small pl-1"
    >
      <div
        :class="{
          'color-danger': !item.algoliaResult
        }"
        :title="get(entities[item.entity], 'label')"
        class="col-3 flex-fill text-ellipsis"
        v-html="get(entities[item.entity], 'label')"
      />
      <div
        v-if="item.algoliaResult"
        :title="templateToShow(item, 'templateSelect')"
        class="col-7 flex-fill text-ellipsis"
        v-html="templateToShow(item, 'templateList')"
      />
      <div
        v-else
        class="col-7 color-danger"
      >
        {{ `${item.entityUuid} not found` }}
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-button
          :disabled="!!item.autoLinked"
          :face="FACES.text"
          square
          @clickbutton="removeItem(itemIndex)"
        >
          <ui-icon
            :icon="ICONS.trash"
            :size="ICONS_SIZES.small"
            square
          />
        </ui-button>
      </div>
    </div>
  </div>
</template>
